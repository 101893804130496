import React, { useEffect, useState } from 'react';
import './home.scss';
import TextAnimation from 'react-text-animations';

var mediaRecorder; // set mediaRecorder as  an globally accessible
var audioText;
// const DG_KEY = process.env.REACT_APP_DEEPGRAM_KEY;
const DG_KEY = "2349d6026b7a71823fba4c082a3b10411d2be6d0";

const NewVoiceQuestionBot = () => {
  let currentText = ''; // if you want record all audio even if you stoped and restart MediaRecorder, so you should set it as a global variable

  const [record, setRecord] = useState(false);
  const [text, setText] = useState('');
  const [cc, setCc] = useState(false);
  const [animation, setAnimation] = useState(false);

  const startRec = () => {
    setRecord(true);
    setText('');
  };

  const stopRec = async () => {
    setRecord(false);
    if (record && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      console.log(mediaRecorder)
      // mediaRecorder.stream.stop();
      mediaRecorder.stream.getTracks().forEach( track => track.stop() );
      mediaRecorder = null;
    }
  
  };

  const deepGramAudio2text = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      if (!MediaRecorder.isTypeSupported('audio/webm')) {
        return alert('Browser not supported');
      }

      var options = { mimeType: 'video/webm' };
      mediaRecorder = new MediaRecorder(stream, options);

      const socket = new WebSocket(`wss://api.deepgram.com/v1/listen`, [
        'token',
        DG_KEY,
      ]);

      socket.onopen = () => {
        mediaRecorder.addEventListener('dataavailable', async (event) => {
          if (event.data.size > 0 && socket.readyState == 1) {
            socket.send(event.data);
          }
        });
      };

      mediaRecorder.start(1100);
      console.log('started');

      socket.onmessage = async (message) => {
        console.log(message)
        const received = await JSON.parse(message.data);
        console.log(received)
        const transcript = received.channel.alternatives[0].transcript;
        if (transcript && received.is_final) {
          currentText = currentText.concat(' ' + transcript);
          audioText = currentText;
          console.log(audioText);
          setText(audioText);
        }
      };
    });
  };

  useEffect(() => {
    if (record) {
      deepGramAudio2text();
    }
  }, [record]);

  let leters = text.split(' ');
  let last2 = leters.slice(-2);

  return (
    <div>
      <div className='homePage'>
        {/* <a href='https://deepgram.com' target='_blank'>
          Deepgram AI 🤖
        </a> */}

        <div style={{ display: animation ? 'block' : 'none' }}>
          <p className={`rotatingText-adjective hide`}>
            {leters.slice(-14, -7).map((i) => i + ' ')}
          </p>
          <p className={`rotatingText-adjective show`}>
            {leters.slice(-7).map((i) => i + ' ')}
          </p>
        </div>

        <div>
          <p>{leters.slice(-14, -7).map((i) => i + ' ')}</p>
          <div style={{ display: 'flex', marginTop: '5px' }}>
            <p>{leters.slice(-7, -2).map((i) => i + ' ')}</p>
            <TextAnimation.Slide
              target='#'
              text={last2}
              animation={{
                duration: 1000,
                delay: 2000,
                timingFunction: 'ease-out',
              }}
              // loop={false}
            >
              &nbsp;#
            </TextAnimation.Slide>
          </div>
        </div>

        <button onClick={startRec} type='button' id='start'>
          Start
        </button>
        <button onClick={stopRec} type='button' id='stop'>
          Stop
        </button>

        <p>
          click <a href='#start'>start</a> button speak, wait, finaly click{' '}
          <a href='#stop'>stop</a> button
        </p>
        <p>
          <a onClick={() => setCc((state) => !state)}>
            {cc ? 'hide cc' : 'show cc'}
          </a>
        </p>
        <p>
          <a onClick={() => setAnimation((state) => !state)}>
            {cc ? 'hide animation' : 'show animation'}
          </a>
        </p>
      </div>
      <textarea
        className='textarea'
        type='text'
        value={text}
        readOnly
        style={{ display: cc ? 'block' : 'none' }}
      />
    </div>
  );
};

export default NewVoiceQuestionBot;


// import React, { useState, useRef, useEffect } from 'react';
// import axios from 'axios';
// import AudioPlayer from './AudioPlayer';

// const NewVoiceQuestionBot = () => {
//   // const [text, setText] = useState("Hello, how can I help you today? This is a long paragraph. It will be split into chunks.");
//   // const [loading, setLoading] = useState(false);
//   // const [error, setError] = useState(null);
//   // const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
//   // const sourceNodeRef = useRef(null);

//   // const splitText = (text) => {
//   //   return text.split('.').map(sentence => sentence.trim()).filter(sentence => sentence);
//   // };

//   // const fetchTTS = async (chunk) => {
//   //   const url = "https://api.deepgram.com/v1/speak?model=aura-asteria-en";
//   //   const apiKey = "2349d6026b7a71823fba4c082a3b10411d2be6d0";
//   //   const data = { text: chunk };

//   //   const config = {
//   //     headers: {
//   //       Authorization: `Token ${apiKey}`,
//   //       "Content-Type": "application/json",
//   //     },
//   //     responseType: "stream",
//   //   };

//   //   try {
//   //     const response = await axios.post(url, data, config);
//   //     if (response.status !== 200) {
//   //       throw new Error(`HTTP error! Status: ${response.status}`);
//   //     }

//   //     response.data.on('data', (chunk) => {
//   //       audioContextRef.current.decodeAudioData(chunk.buffer, (buffer) => {
//   //         playAudioBuffer(buffer);
//   //       });
//   //     });

//   //     response.data.on('end', () => {
//   //       console.log("Streaming ended.");
//   //     });

//   //   } catch (error) {
//   //     throw new Error(error.message);
//   //   }
//   // };

//   // const playAudioBuffer = (buffer) => {
//   //   const audioContext = audioContextRef.current;
//   //   const sourceNode = audioContext.createBufferSource();
//   //   sourceNode.buffer = buffer;
//   //   sourceNode.connect(audioContext.destination);
//   //   sourceNode.start();

//   //   if (sourceNodeRef.current) {
//   //     sourceNodeRef.current.stop();
//   //   }
//   //   sourceNodeRef.current = sourceNode;
//   // };

//   // const handleGenerateAudio = async () => {
//   //   setLoading(true);
//   //   setError(null);
//   //   const chunks = splitText(text);

//   //   for (const chunk of chunks) {
//   //     try {
//   //       await fetchTTS(chunk);
//   //     } catch (error) {
//   //       setError(error.message);
//   //       break;
//   //     }
//   //   }
//   //   setLoading(false);
//   // };

//   // return (
//   //   <div className="tts-component">
//   //     <h2>Text-to-Speech</h2>
//   //     {/* <AudioPlayer/> */}
//   //     <textarea
//   //       value={text}
//   //       onChange={(e) => setText(e.target.value)}
//   //       rows="4"
//   //       cols="50"
//   //       placeholder="Enter text here..."
//   //     />
//   //     <button onClick={handleGenerateAudio} disabled={loading}>
//   //       {loading ? 'Generating...' : 'Generate Audio'}
//   //     </button>
//   //     {error && <p style={{ color: 'red' }}>Error: {error}</p>}
//   //   </div>
//   // );
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [text, setText] = useState("Hello, how can I help you today?");
//   const [audioSrc, setAudioSrc] = useState(null);

//   const handleTTS = async () => {
//     setLoading(true);
//     setError(null);
//     const url = "https://api.deepgram.com/v1/speak?model=aura-asteria-en";
//     const apiKey = "2349d6026b7a71823fba4c082a3b10411d2be6d0";
//     const data = {
//       text: text,
//     };

//     const config = {
//       headers: {
//         Authorization: `Token ${apiKey}`,
//         "Content-Type": "application/json",
//       },
//       responseType: "blob", // Ensure the response is treated as a blob
//     };

//     try {
//       const response = await axios.post(url, data, config);

//       if (response.status !== 200) {
//         setError(`HTTP error! Status: ${response.status}`);
//         setLoading(false);
//         return;
//       }

//       const blob = new Blob([response.data], { type: 'audio/mpeg' });
//       const new_url = URL.createObjectURL(blob);
//       setAudioSrc(new_url);
//       console.log("Audio generated successfully.");
//     } catch (error) {
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="tts-component">
//       <h2>Text-to-Speech</h2>
//       <textarea
//         value={text}
//         onChange={(e) => setText(e.target.value)}
//         rows="4"
//         cols="50"
//         placeholder="Enter text here..."
//       />
//       <button onClick={handleTTS} disabled={loading}>
//         {loading ? 'Generating...' : 'Generate Audio'}
//       </button>
//       {error && <p style={{ color: 'red' }}>Error: {error}</p>}
//       {audioSrc && <audio controls autoPlay src={audioSrc} />}
//     </div>
//   );
// };

// export default NewVoiceQuestionBot;
