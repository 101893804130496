import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import axios from "axios";

const FaceCombine = () => {

  const context = useContext(UserContext)
  const { user, MODEL_URL, host } = context

  const [motherImage, setMotherImage] = useState(null);
  const [fatherImage, setFatherImage] = useState(null);
  const [motherImageInput, setMotherImageInput] = useState(null);
  const [fatherImageInput, setFatherImageInput] = useState(null);
  const [kidImage, setKidImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleMotherImageChange = (e) => {
    const file = e.target.files[0];
    setMotherImageInput(file)
    setMotherImage(URL.createObjectURL(file));
  };

  const handleFatherImageChange = (e) => {
    const file = e.target.files[0];
    setFatherImageInput(file)
    setFatherImage(URL.createObjectURL(file));
  };

  const handleSubmit = async () => {
    if (!motherImage || !fatherImage) {
      alert('Please upload both images!');
      return;
    }

    setLoading(true);
    // Simulate image generation process


    const formData = new FormData();
    formData.append('motherImage', motherImageInput);
    formData.append('fatherImage', fatherImageInput);

    try {
      const response = await axios.post(`${host}/api/generate-child-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'auth-token': localStorage.getItem("token")
        },
      });

      if (response.data.imageUrl) {
        setKidImage(response.data.imageUrl);
      } else {
        alert('Failed to generate image!');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
      alert('Error uploading images.');
    } finally{
      setLoading(false);

    }

    // setTimeout(() => {
    //   setKidImage(""); // Replace with actual response
    //   setLoading(false);
    // }, 2000);
  };

  return (
    <div className="min-h-screen py-5 bg-gradient-to-br from-blue-200 to-pink-200 flex flex-col items-center justify-center">
      <div className="bg-light p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-3xl font-semibold text-center text-gray-700 mb-6">
          Predict Your Kid's Image
        </h2>

        <div className="space-y-6">
          {/* Upload Mother's Image */}
          <div className="flex flex-col items-center">
            <label className="block text-gray-700">Mother's Image:</label>
            <div className="mt-2">
              {!motherImage ? (
                <label className="cursor-pointer bg-pink-500 text-white px-4 py-2 rounded-full transition-transform transform hover:scale-105">
                  Choose Mother's Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleMotherImageChange}
                    className="hidden"
                  />
                </label>
              ) : (
                <>
                  <img
                    src={motherImage}
                    alt="Mother Preview"
                    className="mt-4 mx-auto h-24 w-24 object-cover rounded-full border-4 border-pink-500 transition-transform transform hover:scale-105 duration-300"
                  />
                  <label className="cursor-pointer mt-2 text-gray-600 underline transition-transform transform hover:scale-105">
                    Replace Mother's Image
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleMotherImageChange}
                      className="hidden"
                    />
                  </label>
                </>
              )}
            </div>
          </div>

          {/* Upload Father's Image */}
          <div className="flex flex-col items-center">
            <label className="block text-gray-700">Father's Image:</label>
            <div className="mt-2">
              {!fatherImage ? (
                <label className="cursor-pointer bg-dark text-white px-4 py-2 rounded-full transition-transform transform hover:scale-105">
                  Choose Father's Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFatherImageChange}
                    className="hidden"
                  />
                </label>
              ) : (
                <>
                  <img
                    src={fatherImage}
                    alt="Father Preview"
                    className="mt-4 mx-auto h-24 w-24 object-cover rounded-full border-4 border-blue-500 transition-transform transform hover:scale-105 duration-300"
                  />
                  <label className="cursor-pointer mt-2 text-dark underline transition-transform transform hover:scale-105">
                    Replace Father's Image
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFatherImageChange}
                      className="hidden"
                    />
                  </label>
                </>
              )}
            </div>
          </div>

          {/* Submit Button */}
          <button
            onClick={handleSubmit}
            disabled={!motherImage || !fatherImage || loading}
            className={`w-full py-2 mt-6 rounded-full text-white font-semibold transition-transform duration-300 ease-in-out transform ${
              loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-medium hover:scale-105 hover:bg-mediumHover"
            }`}
          >
            {loading ? "Generating..." : "Predict Kid"}
          </button>
        </div>

        {/* Kid Image Preview */}
        {kidImage && (
          <div className="mt-8 flex flex-col items-center justify-center">
            <h3 className="text-xl font-semibold text-gray-700">
              Your Kid's Image
            </h3>
            <img
              src={kidImage}
              alt="Kid Preview"
              className="mt-4 mx-auto h-32 w-32 object-cover rounded-full border-4 border-yellow-500 animate-bounce"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FaceCombine;
