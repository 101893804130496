import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { lazy } from "react";
// import UserContext from "../context/UserContext.jsx";
// import MainChatBotVoice from "../views/chatbot/MainChatBotVoice.jsx";
import React from "react";
import ContactUs from "../views/contact/ContactUs.jsx";
// import NewChatTheme from "../views/chatbot/NewChatTheme.jsx";
import NewVoiceQuestionBot from "../views/chatbot/NewVoiceQuestionBot.jsx";
import FaceCombine from "../views/faceCombine/FaceCombine.jsx";
const TermsAndConditions  = lazy(() => import("../views/policies/TermsAndCondition.jsx"));
const PrivacyPolicy  = lazy(() => import("../views/policies/PrivacyPolicies.jsx"));
// import NewVoiceBot from "../views/chatbot/NewVoiceBot.jsx";
// import New2MainChatBot from "../views/chatbot/New2MainChatBot.jsx";
const AudioJournal = lazy(() => import("../views/audio/AudioJournal.jsx"));
// const FaceCombine = lazy(()=> import("../views/face-combine/FaceCombine.jsx"));
// FaceCombine
const NayaQuestionBot = lazy(() => import("../views/newChatBots/NayaQuestionBot.jsx"));
const NayaStressBot = lazy(() => import("../views/newChatBots/NayaStressBot.jsx"));
const NayaTCMBot = lazy(() => import("../views/newChatBots/NayaTCMBot.jsx"));
const NayaChefBot = lazy(() => import("../views/newChatBots/NayaChefBot.jsx"));
const NayaMainBot = lazy(() => import("../views/newChatBots/NayaMainBot.jsx"));
// const NewTherapyBot   = lazy(() => import("../views/chatbot/NewTherapyBot.jsx"));
const NewConsultancyBot  = lazy(() => import( "../views/chatbot/NewConsultancyBot.jsx"));
// const NewMainChatBot  = lazy(() => import( "../views/chatbot/NewMainChatBot.jsx"));
// const NewQuestionBot  = lazy(() => import("../views/chatbot/NewQuestionBot.jsx"));
// const NewStress = lazy(() => import( "../views/chatbot/NewStress.jsx"));
// const NewTCMBot = lazy(() => import("../views/chatbot/NewTCMBot.jsx"));
const MacroPage = lazy(() => import("../components/macros/MacroPage.jsx"));
// const TCMBot = lazy(() => import("../views/chatbot/TCMBot.jsx"));
// const StressBot = lazy(()=> import("../views/chatbot/Stress.jsx"));
const Learning = lazy(()=> import("../components/stress/Learning.jsx"));
// const LockedDashboard =  lazy(() => import("../views/locked-dashboard/LockedDashboard.jsx"));
const Login = lazy(() => import("../views/auth/Login.jsx"));
const Signup = lazy(() => import("../views/auth/Signup.jsx"));
const Forgot = lazy(() => import("../views/auth/Forgot.jsx"));
const Verify = lazy(() => import("../views/auth/Verify.jsx"));
const ResetPassword = lazy(() => import("../views/auth/ResetPassword.jsx"));
const Profile = lazy(() => import("../views/profile/Profile.jsx"));
const Logout = lazy(() => import("../views/auth/Logout.jsx"));
const WeekMenu = lazy(() => import("../components/weekMenu/WeekMenu.jsx"));
// const MainChatBot = lazy(() => import("../views/chatbot/MainChatBot.jsx"));
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const TongueAnalysis = lazy(() => import("../views/TongueAnalysis/TongueAnalysis.jsx"));
const Starter = lazy(() => import("../views/Starter.js"));
// const QuestionBot = lazy(() => import("../views/chatbot/QuestionBot.jsx"));
// const TherapyBot = lazy(() => import("../views/chatbot/TherapyBot.jsx"));
// const VoiceChatBot = lazy(() => import("../views/chatbot/VoiceChatBot.jsx"));
// const MainChatBotVoice = lazy(() =>
//   import("../views/chatbot/MainChatBotVoice.jsx")
// );
const PaymentPlans = lazy(() =>
  import("../components/payment/PaymentPlans.jsx")
);
const PreviousImages = lazy(() =>
  import("../views/TongueAnalysis/PreviousImages.jsx")
);
// const ConsultancyBot = lazy(() =>
//   import("../views/chatbot/ConsultancyBot.jsx")
// );

const NewRoutes = ({ user }) => (
  <Routes>
    <Route path='/terms' element={<TermsAndConditions/>}/>
    <Route path='/privacy' element={<PrivacyPolicy/>}/>

    {( localStorage.getItem("token")) ? (
      <></>
    ) : (
      <Route path="/" element={<Outlet />}>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/forgot-password/:token" element={<ResetPassword />} />
        <Route path="/verify/:token" element={<Verify />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    )}
    <Route path="/logout" element={<Logout />} />
    <Route path="/dashboard" element={<FullLayout />}>
      
      {user && user.subscription.plan === "trial" && (
        <>
        {/* <Route path="/dashboard/locked-dashboard/" element={<LockedDashboard btnText="Sign Up"  text="Get Access Your Fully Personalized Fertility Coach with Your Subscription" btnLink = "/dashboard/consultancy-chat"/>} /> */}
        {/* <Route path="/dashboard/consultancy-chat/" element={<ConsultancyBot />}/> */}
        <Route path="/dashboard/consultancy-chat/" element={<NewConsultancyBot />}/>
        </>
      )}
      
      
      {(user &&
        // user.subscription.plan !== "trial" &&
        // user.subscription.plan !== "none"
        //  &&
        // user.index<=7
        user.hasSubscribed
        &&
        !user?.hasPersonalDetails 
      ) &&
        <>
            {/* <Route path="/dashboard/locked-dashboard" element={<LockedDashboard/>} /> */}

        <Route path="/dashboard/question-bot/" element={<NayaQuestionBot />} />
            {/* <Route path="/dashboard/question-bot/" element={<QuestionBot />} /> */}
            {/* <Route path="/dashboard/question-bot/" element={<NayaQuestionBot />} /> */}
            {/* <Route path="/dashboard/voice-chat-bot" element={<VoiceChatBot />}/> */}

          </>

          // :
          // <>
          //   <Route path="*" element={<Navigate to="/dashboard" />} />
          // </>
        }
      <Route path="/dashboard/" element={<Starter />} />
      {/* <Route path="/dashboard/talk-with-kirsten-new/" element={<NayaMainBot />}/> */}
      {/* <Route path="/dashboard/talk-with-chef-new/" element={<NayaChefBot />}/> */}
      {user &&
      user?.hasPersonalDetails &&
      // user.index>7&&
      // user?.subscription?.plan !== "trial" && 
      // user?.subscription?.plan !== "none"
    // &&
     user.hasSubscribed
      // &&
      // user?.subscription?.current_period_end > parseInt(Date.now() / 1000) 
      ? (
        <>
          {/* <Route path="/dashboard/talk-with-kirsten/" element={<MainChatBot />}/> */}
          {/* <Route path="/dashboard/talk-with-kirsten/" element={<NewMainChatBot />}/> */}
          <Route path="/dashboard/talk-with-kirsten/" element={<NayaMainBot />}/>
          {/* <Route path="/dashboard/talk-with-kirsten-/" element={<MainChatBotVoice />}/>  */}
          {/* <Route path="/dashboard/talk-with-chef" element={<TherapyBot />} /> */}
          {/* <Route path="/dashboard/talk-with-chef" element={<NewTherapyBot />} /> */}
          <Route path="/dashboard/talk-with-chef" element={<NayaChefBot />} />
          <Route path="/dashboard/tongue-analysis" element={<TongueAnalysis />}/>
          <Route path="/dashboard/tongue-images" element={<PreviousImages />} />
          
          <Route path="/dashboard/weeklyMenu" element={<WeekMenu />} />
          <Route path="/dashboard/past-macros" element={<MacroPage />} />

        </>
      ) : (
        // <Route path="*" element={<LockedDashboard/>} />
        <></>
      )}
      
      {/* <Route path="/dashboard/new-chat-theme" element={<NewChatTheme />}/> */}
      {/* <Route path="/dashboard/tcm" element={<TCMBot />}/> */}
      {/* <Route path="/dashboard/tcm" element={<NewTCMBot />}/> */}
      <Route path="/dashboard/tcm" element={<NayaTCMBot />}/>
      <Route path="/dashboard/audio-journal" element={<AudioJournal />}/>
      <Route path="/dashboard/baby-face" element={<FaceCombine />} />
      {/* <Route path="/dashboard/stress-relief" element={<StressBot />}/> */}
      {/* <Route path="/dashboard/stress-relief" element={<NewStress />}/> */}
      <Route path="/dashboard/stress-relief" element={<NayaStressBot />}/>
      <Route path="/dashboard/learnings" element={<Learning />} />
      <Route path="/dashboard/payment-plans" element={<PaymentPlans />} />
      <Route path="/dashboard/contactUs" element={<ContactUs/>}/>
      <Route path="/dashboard/user-profile" element={<Profile />} />
      <Route path="/dashboard/voice-chat-bot" element={<NewVoiceQuestionBot />}/>
    </Route>

    <Route path="*" element={<Navigate to="/dashboard" />} />
  </Routes>
);

export default NewRoutes;
